<template>
  <div class="Multiple q-px-sm">

    <!-- 轮播图 -->
    <div class="q-py-sm">
      <!-- 骨架 -->
      <div v-if="swiperListGujia">
        <q-skeleton class="height_150 full-width relative-position" />
      </div>

      <q-carousel v-else v-model="slide" swipeable navigation infinite animated :autoplay="3500"
        :transition-duration="3500" height="150px" style="border-radius: 15px;" transition-prev transition-next>
        <q-carousel-slide v-for="(item, index) in swiperList" :name="index" :key="index" :img-src="item.image_url"
          style="background-size: 100% 150px;" @click="onClickSwiper(item, 0)" />
      </q-carousel>
    </div>

    <!-- 筛选 -->
    <div class="rtl Dflex wrap justify-between q-mt-md">
      <div v-for="item in filter" class="q-mb-sm" @click="onClickFilter(item)">
        <q-img class="width_80 height_45 border-r-15" :src="item.image" />
        <div class="Ukij text-center text-grey-2 q-mt-sm">{{ item.name }}</div>
      </div>
    </div>

    <!-- 最新添加内容 标题 -->
    <div class="rtl row justify-between items-center">
      <div class="row" style="height: 25px">
        <div class="bg-main-color title_right full-height q-ml-sm"></div>
        <div class="Ukij text-grey-2 font_size20">ئەڭ يېڭى تىياتىرلار</div>
      </div>
      <div class="row" @click="onClickMore(0)">
        <div class="Ukij text-white q-px-sm border-r-8 bg-main-color">تېخىمۇ كۆپ ...</div>
      </div>
    </div>
    <!-- 窗口 -->
    <div class="rtl q-my-sm row">
      <!-- 骨架 -->
      <div v-if="movieLoadig" class="rtl q-my-sm justify-between row full-width">
        <div v-for="item in 6" class="example-item width_30p q-mb-md box-list">
          <q-skeleton class="height_150 full-width relative-position" />
          <q-skeleton class="q-mt-sm" />
        </div>
      </div>

      <q-intersection v-if="!movieLoadig" v-for="(item, index) in newMovie" :key="index" transition="scale"
        class="example-item width_30p q-mb-md box-list" @click="onClickMovie(item, 5)">
        <div class="height_150 full-width relative-position border-r-8 overflow-h">
          <q-img :src="item.image_url ? item.image_url : ''" class="full-width full-height border-r-10" />
          <div v-if="item.is_free == 1" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-orange-10 font_size12"
            style="border-radius: 0 0 0 15px;">
            <div class="e-ml-5 fontW-900">VIP</div>
          </div>
          <div v-if="item.is_free == 0" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-positive font_size12"
            style="border-radius: 0 0 0 15px;">
            <div class="e-ml-5 Ukij">ھەقسىز</div>
          </div>
          <div class="row absolute bg-opacity e-t-0 e-l-0 text-orange-10 fontW-900 font_size12">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3">{{ item.score }}</div>
              <q-img :src="`${reqUrlData.url.icon_url}star-juse.svg`" style="width: 13px; height: 13px" />
            </div>
          </div>
          <div class="row absolute bg-opacity e-b-0 e-r-0 text-orange-10 fontW-900 font_size10">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3 Ukij">
                <span>
                  <span>{{ item.totalSet }}</span>
                  <span> / </span>
                  <span v-if="item.multiSet.length !== item.totalSet">{{ item.multiSet.length }}</span>
                </span>

                <span v-if="item.multiSet.length !== item.totalSet">-قىسىم</span>
                <span v-else>تامام</span>
              </div>
            </div>
          </div>
        </div>
        <div class="Ukij text-grey-4 q-mt-sm font_size14 text-ellipsis text-center">{{ item.name }}</div>
        <div class="Ukij text-grey-6 font_size10 text-ellipsis text-center">
          {{ item.play_year }}.{{ item.country[0] ? item.country[0].name : '' }}. {{ item.category[0] ?
          item.category[0].name : '' }}
        </div>
        <div class="row" style="margin-top: 5px;">
          <q-badge v-for="i of item.language_type" color="grey-7" class="Ukij font font_size8 margin-2-L-0 q-ml-sm">
            <span>{{ i == 1 ? 'ئۇيغۇچە' : i == 2 ? 'خەنزۇچە' : i == 3 ? 'ئەسلى ئاۋاز' : '' }}</span>
          </q-badge>
        </div>
      </q-intersection>
    </div>

    <!-- 国产片 标题 -->
    <div class="rtl row justify-between items-center">
      <div class="row" style="height: 25px">
        <div class="bg-main-color title_right full-height q-ml-sm"></div>
        <div class="Ukij text-grey-2 font_size20">دۆلىتىمىز تىياتىرلىرى</div>
      </div>
      <div class="row" @click="onClickMore(1)">
        <div class="Ukij text-white q-px-sm border-r-8 bg-main-color">تېخىمۇ كۆپ ...</div>
      </div>
    </div>
    <!-- 国产片 Top 图 -->
    <div class="q-mt-sm" @click="onClickMore(1)">
      <q-img class="unsetImage" :src="`${reqUrlData.url.image_url}multiple_swiper_china.jpg`" />
    </div>
    <!-- 窗口 -->
    <div class="rtl q-my-sm row">
      <!-- 骨架 -->
      <div v-if="movieLoadig" class="rtl q-my-sm row full-width">
        <div v-for="item in 8" class="example-item width_23p q-mb-md box-list-1">
          <q-skeleton class="height_150 full-width relative-position" />
          <q-skeleton class="q-mt-sm" />
        </div>
      </div>

      <q-intersection v-if="!movieLoadig" v-for="(item, index) in china" :key="index" transition="scale"
        class="example-item width_23p q-mb-md box-list-1" @click="onClickMovie(item, 5)">
        <div class="height_150 full-width relative-position border-r-8 overflow-h">
          <q-img :src="item.image_url ? item.image_url : ''" class="full-width full-height border-r-10" />
          <div v-if="item.is_free == 1" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-orange-10 font_size10"
            style="border-radius: 0 0 0 10px;">
            <div class="e-ml-5 fontW-900">VIP</div>
          </div>
          <div v-if="item.is_free == 0" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-positive font_size10"
            style="border-radius: 0 0 0 10px;">
            <div class="e-ml-5 Ukij">ھەقسىز</div>
          </div>
          <div class="row absolute bg-opacity e-t-0 e-l-0 text-orange-10 fontW-900 font_size10">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3">{{ item.score }}</div>
              <q-img :src="`${reqUrlData.url.icon_url}star-juse.svg`" style="width: 10px; height: 10px" />
            </div>
          </div>
          <div class="row absolute bg-opacity e-b-0 e-r-0 text-orange-10 fontW-900 font_size10">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3 Ukij">
                <span>
                  <span>{{ item.totalSet }}</span>
                  <span> / </span>
                  <span v-if="item.multiSet.length !== item.totalSet">{{ item.multiSet.length }}</span>
                </span>

                <span v-if="item.multiSet.length !== item.totalSet">-قىسىم</span>
                <span v-else>تامام</span>
              </div>
            </div>
          </div>
        </div>
        <div class="Ukij text-grey-4 q-mt-sm font_size12 text-ellipsis text-center">{{ item.name }}</div>
        <div class="Ukij text-grey-6 font_size10 text-ellipsis text-center">
          {{ item.play_year }}.{{ item.country[0] ? item.country[0].name : '' }}. {{ item.category[0] ?
          item.category[0].name : '' }}
        </div>
        <div class="row" style="margin-top: 5px;">
          <q-badge v-for="i of item.language_type" color="grey-7" class="Ukij font font_size8 margin-2-L-0 q-ml-sm">
            <span>{{ i == 1 ? 'ئۇيغۇچە' : i == 2 ? 'خەنزۇچە' : i == 3 ? 'ئەسلى ئاۋاز' : '' }}</span>
          </q-badge>
        </div>
      </q-intersection>
    </div>

    <!-- 美剧片 标题 -->
    <div class="rtl row justify-between items-center">
      <div class="row" style="height: 25px">
        <div class="bg-main-color title_right full-height q-ml-sm"></div>
        <div class="Ukij text-grey-2 font_size20">ئامرىكا تىياتىرلىرى</div>
      </div>
      <div class="row" @click="onClickMore(3)">
        <div class="Ukij text-white q-px-sm border-r-8 bg-main-color">تېخىمۇ كۆپ ...</div>
      </div>
    </div>
    <!-- 美剧片 Top 图 -->
    <div class="q-mt-sm" @click="onClickMore(3)">
      <q-img class="unsetImage" :src="`${reqUrlData.url.image_url}multiple_swiper_USA.jpg`" />
    </div>
    <!-- 窗口 -->
    <div class="rtl q-my-sm row">
      <!-- 骨架 -->
      <div v-if="movieLoadig" class="rtl q-my-sm row full-width">
        <div v-for="item in 8" class="example-item width_23p q-mb-md box-list-1">
          <q-skeleton class="height_150 full-width relative-position" />
          <q-skeleton class="q-mt-sm" />
        </div>
      </div>

      <q-intersection v-if="!movieLoadig" v-for="(item, index) in usa" :key="index" transition="scale"
        class="example-item width_23p q-mb-md box-list-1" @click="onClickMovie(item, 5)">
        <div class="height_150 full-width relative-position border-r-8 overflow-h">
          <q-img :src="item.image_url ? item.image_url : ''" class="full-width full-height border-r-10" />
          <div v-if="item.is_free == 1" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-orange-10 font_size10"
            style="border-radius: 0 0 0 10px;">
            <div class="e-ml-5 fontW-900">VIP</div>
          </div>
          <div v-if="item.is_free == 0" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-positive font_size10"
            style="border-radius: 0 0 0 10px;">
            <div class="e-ml-5 Ukij">ھەقسىز</div>
          </div>
          <div class="row absolute bg-opacity e-t-0 e-l-0 text-orange-10 fontW-900 font_size10">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3">{{ item.score }}</div>
              <q-img :src="`${reqUrlData.url.icon_url}star-juse.svg`" style="width: 10px; height: 10px" />
            </div>
          </div>
          <div class="row absolute bg-opacity e-b-0 e-r-0 text-orange-10 fontW-900 font_size10">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3 Ukij">
                <span>
                  <span>{{ item.totalSet }}</span>
                  <span> / </span>
                  <span v-if="item.multiSet.length !== item.totalSet">{{ item.multiSet.length }}</span>
                </span>

                <span v-if="item.multiSet.length !== item.totalSet">-قىسىم</span>
                <span v-else>تامام</span>
              </div>
            </div>
          </div>
        </div>
        <div class="Ukij text-grey-4 q-mt-sm font_size12 text-ellipsis text-center">{{ item.name }}</div>
        <div class="Ukij text-grey-6 font_size10 text-ellipsis text-center">
          {{ item.play_year }}.{{ item.country[0] ? item.country[0].name : '' }}. {{ item.category[0] ?
          item.category[0].name : '' }}
        </div>
        <div class="row" style="margin-top: 5px;">
          <q-badge v-for="i of item.language_type" color="grey-7" class="Ukij font font_size8 margin-2-L-0 q-ml-sm">
            <span>{{ i == 1 ? 'ئۇيغۇچە' : i == 2 ? 'خەنزۇچە' : i == 3 ? 'ئەسلى ئاۋاز' : '' }}</span>
          </q-badge>
        </div>
      </q-intersection>
    </div>

    <!-- 韩剧片 标题 -->
    <div class="rtl row justify-between items-center">
      <div class="row" style="height: 25px">
        <div class="bg-main-color title_right full-height q-ml-sm"></div>
        <div class="Ukij text-grey-2 font_size20">كورىيە تىياتىرلىرى</div>
      </div>
      <div class="row" @click="onClickMore(13)">
        <div class="Ukij text-white q-px-sm border-r-8 bg-main-color">تېخىمۇ كۆپ ...</div>
      </div>
    </div>
    <!-- 韩剧片 Top 图 -->
    <div class="q-mt-sm" @click="onClickMore(13)">
      <q-img class="unsetImage" :src="`${reqUrlData.url.image_url}multiple_swiper_korea.jpg`" />
    </div>
    <!-- 窗口 -->
    <div class="rtl q-my-sm row">
      <!-- 骨架 -->
      <div v-if="movieLoadig" class="rtl q-my-sm row full-width">
        <div v-for="item in 8" class="example-item width_23p q-mb-md box-list-1">
          <q-skeleton class="height_150 full-width relative-position" />
          <q-skeleton class="q-mt-sm" />
        </div>
      </div>

      <q-intersection v-if="!movieLoadig" v-for="(item, index) in korea" :key="index" transition="scale"
        class="example-item width_23p q-mb-md box-list-1" @click="onClickMovie(item, 5)">
        <div class="height_150 full-width relative-position border-r-8 overflow-h">
          <q-img :src="item.image_url ? item.image_url : ''" class="full-width full-height border-r-10" />
          <div v-if="item.is_free == 1" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-orange-10 font_size10"
            style="border-radius: 0 0 0 10px;">
            <div class="e-ml-5 fontW-900">VIP</div>
          </div>
          <div v-if="item.is_free == 0" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-positive font_size10"
            style="border-radius: 0 0 0 10px;">
            <div class="e-ml-5 Ukij">ھەقسىز</div>
          </div>
          <div class="row absolute bg-opacity e-t-0 e-l-0 text-orange-10 fontW-900 font_size10">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3">{{ item.score }}</div>
              <q-img :src="`${reqUrlData.url.icon_url}star-juse.svg`" style="width: 10px; height: 10px" />
            </div>
          </div>
          <div class="row absolute bg-opacity e-b-0 e-r-0 text-orange-10 fontW-900 font_size10">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3 Ukij">
                <span>
                  <span>{{ item.totalSet }}</span>
                  <span> / </span>
                  <span v-if="item.multiSet.length !== item.totalSet">{{ item.multiSet.length }}</span>
                </span>

                <span v-if="item.multiSet.length !== item.totalSet">-قىسىم</span>
                <span v-else>تامام</span>
              </div>
            </div>
          </div>
        </div>
        <div class="Ukij text-grey-4 q-mt-sm font_size12 text-ellipsis text-center">{{ item.name }}</div>
        <div class="Ukij text-grey-6 font_size10 text-ellipsis text-center">
          {{ item.play_year }}.{{ item.country[0] ? item.country[0].name : '' }}. {{ item.category[0] ?
          item.category[0].name : '' }}
        </div>
        <div class="row" style="margin-top: 5px;">
          <q-badge v-for="i of item.language_type" color="grey-7" class="Ukij font font_size8 margin-2-L-0 q-ml-sm">
            <span>{{ i == 1 ? 'ئۇيغۇچە' : i == 2 ? 'خەنزۇچە' : i == 3 ? 'ئەسلى ئاۋاز' : '' }}</span>
          </q-badge>
        </div>
      </q-intersection>
    </div>

    <!-- 印度片 标题 -->
    <div class="rtl row justify-between items-center">
      <div class="row" style="height: 25px">
        <div class="bg-main-color title_right full-height q-ml-sm"></div>
        <div class="Ukij text-grey-2 font_size20">ھېىندىستان تىياتىرلىرى</div>
      </div>
      <div class="row" @click="onClickMore(5)">
        <div class="Ukij text-white q-px-sm border-r-8 bg-main-color">تېخىمۇ كۆپ ...</div>
      </div>
    </div>
    <!-- 印度片 Top 图 -->
    <div class="q-mt-sm" @click="onClickMore(5)">
      <q-img class="unsetImage" :src="`${reqUrlData.url.image_url}multiple_swiper_india.jpg`" />
    </div>
    <!-- 窗口 -->
    <div class="rtl q-my-sm row">
      <!-- 骨架 -->
      <div v-if="movieLoadig" class="rtl q-my-sm row full-width">
        <div v-for="item in 8" class="example-item width_23p q-mb-md box-list-1">
          <q-skeleton class="height_150 full-width relative-position" />
          <q-skeleton class="q-mt-sm" />
        </div>
      </div>

      <q-intersection v-if="!movieLoadig" v-for="(item, index) in india" :key="index" transition="scale"
        class="example-item width_23p q-mb-md box-list-1" @click="onClickMovie(item, 5)">
        <div class="height_150 full-width relative-position border-r-8 overflow-h">
          <q-img :src="item.image_url ? item.image_url : ''" class="full-width full-height border-r-10" />
          <div v-if="item.is_free == 1" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-orange-10 font_size10"
            style="border-radius: 0 0 0 10px;">
            <div class="e-ml-5 fontW-900">VIP</div>
          </div>
          <div v-if="item.is_free == 0" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-positive font_size10"
            style="border-radius: 0 0 0 10px;">
            <div class="e-ml-5 Ukij">ھەقسىز</div>
          </div>
          <div class="row absolute bg-opacity e-t-0 e-l-0 text-orange-10 fontW-900 font_size10">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3">{{ item.score }}</div>
              <q-img :src="`${reqUrlData.url.icon_url}/star-juse.svg`" style="width: 10px; height: 10px" />
            </div>
          </div>
          <div class="row absolute bg-opacity e-b-0 e-r-0 text-orange-10 fontW-900 font_size10">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3 Ukij">
                <span>
                  <span>{{ item.totalSet }}</span>
                  <span> / </span>
                  <span v-if="item.multiSet.length !== item.totalSet">{{ item.multiSet.length }}</span>
                </span>

                <span v-if="item.multiSet.length !== item.totalSet">-قىسىم</span>
                <span v-else>تامام</span>
              </div>
            </div>
          </div>
        </div>
        <div class="Ukij text-grey-4 q-mt-sm font_size12 text-ellipsis text-center">{{ item.name }}</div>
        <div class="Ukij text-grey-6 font_size10 text-ellipsis text-center">
          {{ item.play_year }}.{{ item.country[0] ? item.country[0].name : '' }}. {{ item.category[0] ?
          item.category[0].name : '' }}
        </div>
        <div class="row" style="margin-top: 5px;">
          <q-badge v-for="i of item.language_type" color="grey-7" class="Ukij font font_size8 margin-2-L-0 q-ml-sm">
            <span>{{ i == 1 ? 'ئۇيغۇچە' : i == 2 ? 'خەنزۇچە' : i == 3 ? 'ئەسلى ئاۋاز' : '' }}</span>
          </q-badge>
        </div>
      </q-intersection>
    </div>

    <!-- 其他片 标题 -->
    <div class="rtl row justify-between items-center">
      <div class="row" style="height: 25px">
        <div class="bg-main-color title_right full-height q-ml-sm"></div>
        <div class="Ukij text-grey-2 font_size20">باشقا تىياتىرلار</div>
      </div>
      <div class="row" @click="onClickMore(0)">
        <div class="Ukij text-white q-px-sm border-r-8 bg-main-color">تېخىمۇ كۆپ ...</div>
      </div>
    </div>
    <!-- 其他片 Top 图 -->
    <div class="q-mt-sm" @click="onClickMore(0)">
      <q-img class="unsetImage" :src="`${reqUrlData.url.image_url}multiple_swiper_other.jpg`" />
    </div>
    <!-- 窗口 -->
    <div class="rtl q-my-sm row">
      <!-- 骨架 -->
      <div v-if="movieLoadig" class="rtl q-my-sm row full-width">
        <div v-for="item in 8" class="example-item width_23p q-mb-md box-list-1">
          <q-skeleton class="height_150 full-width relative-position" />
          <q-skeleton class="q-mt-sm" />
        </div>
      </div>

      <q-intersection v-if="!movieLoadig" v-for="(item, index) in other" :key="index" transition="scale"
        class="example-item width_23p q-mb-md box-list-1" @click="onClickMovie(item, 5)">
        <div class="height_150 full-width relative-position border-r-8 overflow-h">
          <q-img :src="item.image_url ? item.image_url : ''" class="full-width full-height border-r-10" />
          <div v-if="item.is_free == 1" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-orange-10 font_size10"
            style="border-radius: 0 0 0 10px;">
            <div class="e-ml-5 fontW-900">VIP</div>
          </div>
          <div v-if="item.is_free == 0" class="row absolute e-t-0 e-r-0 e-px-5 text-white bg-positive font_size10"
            style="border-radius: 0 0 0 10px;">
            <div class="e-ml-5 Ukij">ھەقسىز</div>
          </div>
          <div class="row absolute bg-opacity e-t-0 e-l-0 text-orange-10 fontW-900 font_size10">
            <div class="e-ml-5 row align-center">
              <div class="e-mx-3">{{ item.score }}</div>
              <q-img :src="`${reqUrlData.url.icon_url}star-juse.svg`" style="width: 10px; height: 10px" />
            </div>
          </div>
        </div>
        <div class="Ukij text-grey-4 q-mt-sm font_size12 text-ellipsis text-center">{{ item.name }}</div>
        <div class="Ukij text-grey-6 font_size10 text-ellipsis text-center">
          {{ item.play_year }}.{{ item.country[0] ? item.country[0].name : '' }}. {{ item.category[0] ?
          item.category[0].name : '' }}
        </div>
        <div class="row" style="margin-top: 5px;">
          <q-badge v-for="i of item.language_type" color="grey-7" class="Ukij font font_size8 margin-2-L-0 q-ml-sm">
            <span>{{ i == 1 ? 'ئۇيغۇچە' : i == 2 ? 'خەنزۇچە' : i == 3 ? 'ئەسلى ئاۋاز' : '' }}</span>
          </q-badge>
        </div>
      </q-intersection>
    </div>

    <!-- 返回顶部 -->
    <div v-if="showBackToTop" class="back-to-top">
      <div @click="scroll_top">
        <img :src="`${reqUrlData.url.icon_url}toTop.svg`" style="width: 50px; height: 50px" />
      </div>
    </div>

    <!-- 保险距离 -->
    <div class="e-pb-100"></div>

    <!-- Tab -->
    <Tab tab_active="2"></Tab>
  </div>
</template>

<script>
import Tab from '../../components/tab/Tab.vue';
import { ref } from 'vue';
import { reqMultiple } from '../../request/api';
import { useMultipleStore } from "../../store/homeStore.js";
import { reqUrl } from '@/request/url.js';

export default {
  name: 'Multiple',
  components: {
    Tab,
  },
  data () {
    return {
      slide: ref(0),
      swiperList: useMultipleStore().swiperList,
      reqUrlData: reqUrl,
      filter: [
        { id: 1, name: 'جۇڭگۇ', country: 1, image: `${reqUrl.url.image_url}multiple_china.jpg` },
        { id: 2, name: 'ئامرىكا', country: 3, image: `${reqUrl.url.image_url}multiple_american.jpg` },
        { id: 3, name: 'كورىيە', country: 13, image: `${reqUrl.url.image_url}multiple_korean.jpg` },
        { id: 4, name: 'ھېىندىستان', country: 5, image: `${reqUrl.url.image_url}multiple_india.jpg` },
        { id: 5, name: 'ھەركەتلىك', category: 1, image: `${reqUrl.url.image_url}multiple_action.jpg` },
        { id: 6, name: 'مۇھەببەت', category: 5, image: `${reqUrl.url.image_url}multiple_love.jpg` },
        { id: 7, name: 'فانتازىيە', category: 2, image: `${reqUrl.url.image_url}multiple_scienceFiction.jpg` },
        { id: 8, name: 'كارتۇن', category: 8, image: `${reqUrl.url.image_url}multiple_cartoon.jpg` }
      ],
      newMovie: useMultipleStore().newMovie,
      china: useMultipleStore().china,
      india: useMultipleStore().india,
      korea: useMultipleStore().korea,
      usa: useMultipleStore().usa,
      other: useMultipleStore().other,
      movieLoadig: useMultipleStore().movieLoadig,
      showBackToTop: false,
      swiperListGujia: useMultipleStore().swiperListGujia,
      singleStore: useMultipleStore(),
    }
  },
  created () {
    this.getPost();
    this.scroll_top();
  },
  methods: {
    // 获取数据
    async getPost () {
      if (useMultipleStore().swiperList.length > 0) return;
      await reqMultiple().then(res => {
        if (res.status == 200) {
          this.swiperList = res.data.swipers.data;
          this.newMovie = res.data.newMovie.data;
          this.china = res.data.china.data;
          this.india = res.data.india.data;
          this.korea = res.data.korea.data;
          this.usa = res.data.usa.data;
          this.other = res.data.other.data;
          this.movieLoadig = false;
          this.swiperListGujia = false;

          // 写入 Pinia 数据
          useMultipleStore().swiperList = res.data.swipers.data;
          useMultipleStore().newMovie = res.data.newMovie.data;
          useMultipleStore().china = res.data.china.data;
          useMultipleStore().india = res.data.india.data;
          useMultipleStore().korea = res.data.korea.data;
          useMultipleStore().usa = res.data.usa.data;
          useMultipleStore().other = res.data.other.data;
          useMultipleStore().movieLoadig = false;
          useMultipleStore().swiperListGujia = false;
        }
      }).catch(err => { })
    },

    // 轮播图点击
    onClickSwiper (item, type) {
      let data = {
        type: type,
        id: item.content_id
      }

      this.$router.push({ path: '/detailed', query: data })
    },

    // 点击筛选
    onClickFilter (item) {
      let data
      switch (item.id) {
        case 1:
          data = {
            country: item.country
          }
          this.$router.push({ path: '/TVseries', query: data })
          break;
        case 2:
          data = {
            country: item.country
          }
          this.$router.push({ path: '/TVseries', query: data })
          break;
        case 3:
          data = {
            country: item.country
          }
          this.$router.push({ path: '/TVseries', query: data })
          break;
        case 4:
          data = {
            country: item.country
          }
          this.$router.push({ path: '/TVseries', query: data })
          break;
        case 5:
          data = {
            category: item.category
          }
          this.$router.push({ path: '/TVseries', query: data })
          break;
        case 6:
          data = {
            category: item.category
          }
          this.$router.push({ path: '/TVseries', query: data })
          break;
        case 7:
          data = {
            category: item.category
          }
          this.$router.push({ path: '/TVseries', query: data })
          break;
        case 8:
          data = {
            category: item.category
          }
          this.$router.push({ path: '/TVseries', query: data })
          break;
        default:
          break;
      }
    },

    // 点击电影
    onClickMovie (item, type) {
      let data = {
        type: type,
        id: item.id,
        category: item.category[0].id
      }
      this.$router.push({ path: '/detailed', query: data })
    },

    // 点击更多
    onClickMore (item) {
      if(item > 0) {
        let data = {
          country: item
        }
        this.$router.push({ path: '/TVseries', query: data })
      } else {
        this.$router.push({ path: '/TVseries' })
      }
    },

    // 滚动到顶部
    scroll_top () {
      window.scrollTo({ top: 0, behavior: 'smooth' }); // 平滑滚动至顶部
    },

    // 监听滚动事件
    handleScroll () {
      if (window.scrollY > 700) { // 当滚动超过700px时显示按钮
        this.showBackToTop = true;
      } else {
        this.showBackToTop = false;
      }
    },

  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>

<style scoped>
.unsetImage img {
  object-fit: unset !important;
}

.box-list {
  margin-left: 5%;
}

.box-list:nth-child(3n) {
  margin-left: 0;
}

.box-list-1 {
  margin-left: 2.5%;
}

.box-list-1:nth-child(4n) {
  margin-left: 0;
}

.example-item {
  height: 210px;
}

.back-to-top {
  position: fixed;
  bottom: 140px;
  right: 20px;
  background-color: #f44336;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  z-index: 2;
}
</style>
