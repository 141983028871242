<template>
  <router-view />
</template>

<script>
import { reqUrl } from "../src/request/url.js";
import VConsole from 'vconsole';

export default {
  data() {
    return {
      //
    }
  },
  created() {
    this.setTitle();
    // const vConsole = new VConsole();
  },
  methods: {
    setTitle() {
      document.title = reqUrl.url.document_tile;
    }
  }
}
</script>

<style>
#app {
  min-height: 100vh;
  background-color: #263238 !important;
}
</style>

